.container {
  @media bp(lg) {
    display: grid;
    grid-template-areas:
      'top right'
      'bottom right';
    grid-template-rows: repeq(2);
    grid-template-columns: 836fr 620fr;
    column-gap: rem(8);
    row-gap: rem(16);
  }

  @media bp(xl) {
    grid-template-columns: 836fr 620fr;
    column-gap: rem(28);
  }
}

.top {
  @media bp(lg) {
    grid-area: top;
  }
}

.bottom {
  margin-top: rem(8);
  display: flex;
  flex-direction: column;
  row-gap: rem(8);

  @media bp(lg) {
    grid-area: bottom;
    margin-top: 0;
    column-gap: rem(8);
    display: grid;
    grid-template-columns: repeq(2);
  }

  @media bp(xl) {
    column-gap: rem(16);
  }
}

.right {
  margin-top: rem(48);
  display: flex;
  flex-direction: column;
  row-gap: rem(18);

  @media bp(lg) {
    row-gap: rem(4);
    margin-top: 0;
    grid-area: right;
  }
}

.rowSeparator {
  color: clr(g-65);
  margin-left: rem(36);

  @media bp(lg) {
    margin-right: rem(12);
    margin-left: rem(56);
  }
}

.rightLabel {
  color: clr(p-40);
  font-size: rem(20);
  font-weight: 500;
  line-height: rem(28);
  letter-spacing: rem(-0.5);

  @media bp(lg) {
    margin-left: rem(12);
    margin-bottom: rem(8);
    font-size: rem(24);
    font-weight: 600;
    line-height: rem(30);
  }
}

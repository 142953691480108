.container {
  font-size: rem(14);
  font-weight: 500;
  line-height: rem(20);
  letter-spacing: rem(-0.5);
  display: flex;
  align-items: center;
  column-gap: rem(8);
}

.image {
  width: rem(24);
  height: rem(24);
  border-radius: 100%;
}

.sep {
  opacity: 0.5;
}

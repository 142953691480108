.grid {
  display: grid;
  row-gap: rem(50);

  @media bp(lg) {
    row-gap: rem(70);
    column-gap: rem(30);
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
}

.leaderboard {
  margin: rem(2) auto;

  @media bp(lg) {
    margin: rem(5) auto;
  }
}

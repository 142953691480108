.title {
  display: flex;
  margin-bottom: rem(20);
  gap: rem(15);
  align-items: center;
}

.icon {
  width: rem(20);
  height: rem(14);
}

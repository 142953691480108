.container {
  display: flex;
  flex-direction: column;
  row-gap: rem(8);
  padding: rem(8);
  border-radius: rem(12);
  background-color: clr(g-90);

  --title-color: clr(b);

  @media bp(lg) {
    border: bdr(1, g-90);
    row-gap: rem(12);
    padding: rem(11);
  }

  @media (hover: hover) and bp(lg) {
    transition: trn(background-color, border-color);

    &:hover {
      background-color: clr(g-70);
      border-color: clr(g-60);

      --title-color: clr(g-25);
    }
  }
}

.imageContainer {
  position: relative;
  aspect-ratio: 342 / 220;

  @media bp(lg) {
    aspect-ratio: 386 / 218;
  }
}

.image {
  border-radius: rem(8);
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: rem(8);
}

.category {
  color: clr(p-60);
  margin-bottom: rem(8);
  font-weight: 500;
  font-size: rem(14);
  line-height: rem(20);
  letter-spacing: rem(-0.5);

  @media bp(lg) {
    letter-spacing: rem(-0.1);
  }
}

.title {
  transition: trn(color);
  color: var(--title-color);
  font-size: rem(20);
  font-weight: 500;
  line-height: rem(26);
  letter-spacing: rem(-0.5);
  flex: 1;

  @media bp(lg) {
    font-weight: 600;

    @mixin lineClamp 6;
  }
}

.author {
  margin-top: rem(24);
  color: clr(g-25);

  @media bp(lg) {
    margin-top: rem(12);
  }
}

.title {
  line-height: rem(20);
  font-weight: bold;

  @mixin transition color;
  @mixin lineClamp 3;

  @media bp(lg) {
    font-size: rem(18);
    line-height: rem(24);
  }
}

.author {
  color: clr(g-60);
}

.container {
  display: grid;
  position: relative;
  min-height: rem(70);
  gap: rem(15);
  grid-template-columns: 1fr rem(70);

  &:hover {
    .title {
      color: clr(p-70);
    }

    .author {
      color: clr(b);
    }
  }

  @media bp(lg) {
    gap: rem(8);
    margin: 0;
    display: flex;
    flex-direction: column-reverse;
    contain: content;
    height: auto;
  }
}

.image {
  height: rem(70);
  width: rem(70);

  @media bp(lg) {
    width: rem(280);
    height: rem(158);
  }
}

.uiHolder {
  display: none;

  @media bp(lg) {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
  }
}

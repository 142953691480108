.container {
  @media bp(lg) {
    position: relative;
    padding: rem(11) rem(11) rem(11) rem(55);
    border: bdr(1, transparent);
  }

  @media (hover: hover) and bp(lg) {
    border-radius: rem(12);
    transition: trn(background-color, color, border-color);

    &:hover {
      background-color: clr(g-70);
      border-color: clr(g-60);
      color: clr(g-25);
    }
  }
}

.number {
  display: none;

  @media bp(lg) {
    background-color: clr(p-60);
    color: clr(cw);
    width: rem(24);
    height: rem(24);
    border-radius: 100%;
    position: absolute;
    left: rem(12);
    top: rem(16);
    display: grid;
    place-items: center;
    font-size: rem(14);
    font-weight: 600;
    line-height: 1;
  }
}

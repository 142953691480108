.container {
  width: calc(100vw - rem(30));

  @media bp(lg) {
    width: 100%;
  }

  /* variables */
  --container-width: var(--wrapper-inner-width);

  @media bp(lg) {
    --container-width: calc(calc(var(--wrapper-inner-width) - rem(30)) / 2);
  }
}

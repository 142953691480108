.container {
  display: flex;
  flex-direction: column;
  row-gap: rem(8);
  padding: rem(8);
  border-radius: rem(12);
  background-color: clr(b);

  @media bp(lg) {
    padding: rem(11);
    display: grid;
    grid-template-columns: repeq(2);
    column-gap: rem(12);
    border: bdr(1, b);
  }

  @media (hover: hover) and bp(lg) {
    transition: trn(background-color, border-color);

    &:hover {
      background-color: clr(g-10);
      border-color: clr(g-25);
    }
  }
}

.imageContainer {
  position: relative;
  aspect-ratio: 342 / 220;

  @media bp(lg) {
    aspect-ratio: initial;
  }
}

.image {
  border-radius: rem(8);
}

.content {
  padding: rem(8);

  @media bp(lg) {
    padding: 16;
    display: flex;
    flex-direction: column;
  }
}

.category {
  color: clr(p-60);
  margin-bottom: rem(8);
  font-size: rem(16);
  line-height: 150%;
  letter-spacing: rem(-0.5);

  @media bp(lg) {
    margin-bottom: rem(16);
  }
}

.titleContainer {
  @media bp(lg) {
    flex: 1;
  }
}

.title {
  color: clr(w);
  font-size: rem(24);
  font-weight: 600;
  line-height: rem(28);
  letter-spacing: rem(-0.5);

  @media bp(lg) {
    font-size: rem(32);
    line-height: rem(38);
    letter-spacing: rem(-1);

    @mixin lineClamp 3;
  }

  @media bp(xl) {
    @mixin lineClamp override-5;
  }
}

.author {
  margin-top: rem(24);
  color: clr(g-60);

  @media bp(lg) {
    margin-top: rem(12);
  }
}

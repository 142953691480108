.container {
  display: flex;
  align-items: stretch;
  column-gap: rem(12);
}

.imageContainer {
  width: rem(96);
  height: rem(112);
  position: relative;

  @media bp(lg) {
    width: rem(144);
    height: rem(128);
  }
}

.image {
  border-radius: rem(8);
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: rem(16);
  font-weight: 600;
  line-height: rem(20);
  letter-spacing: rem(-0.5);
  transition: trn(color);
  flex: 1;

  @mixin lineClamp 3;

  @media bp(lg) {
    font-size: rem(20);
    line-height: rem(24);
  }
}

.bottomLine {
  display: flex;
  align-items: center;
  font-size: rem(12);
  font-weight: 500;
  line-height: rem(16);
  margin-top: rem(10);

  @media bp(lg) {
    font-size: rem(14);
    line-height: rem(20);
    letter-spacing: rem(-0.1);
  }
}

.category {
  margin-bottom: rem(4);
  font-size: rem(12);
  font-weight: 500;
  line-height: rem(16);
  letter-spacing: rem(-0.1);
  color: clr(p-70);

  @media bp(lg) {
    font-size: rem(14);
    line-height: rem(20);
  }
}

.author {
  color: clr(g-25);

  &::after {
    position: relative;
    top: rem(2);
    font-size: rem(16);
    content: '•';
    padding: 0 rem(8);
    color: clr(g-65);
  }
}

.commentsCounter {
  color: clr(g-25);
  position: relative;
  top: rem(1);

  --icon-w: rem(16) !important;
  --icon-h: rem(16) !important;

  @media bp(lg) {
    top: 0;

    --icon-w: rem(20) !important;
    --icon-h: rem(20) !important;
  }
}

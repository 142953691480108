.authorText {
  color: clr(g-20);
  font-weight: bold;
  font-size: rem(12);
  line-height: rem(14);
  margin-top: rem(5);

  @mixin transition color;

  @media bp(lg) {
    margin-top: rem(10);
  }
}

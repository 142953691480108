.titleText {
  font-size: rem(22);
  line-height: rem(26);
  font-weight: bold;

  @mixin transition color;
  @mixin lineClamp 3;

  @media bp(lg) {
    font-size: rem(28);
    line-height: rem(36);
  }
}

.container {
  contain: content;
  overflow: hidden;
  position: relative;

  &:hover {
    .titleText {
      color: clr(p-70);
    }

    .authorText {
      color: clr(b);
    }
  }
}

.title {
  color: clr(cw);
  top: 0;
  width: 100%;
  position: absolute;
  border-radius: rem(2);
  background-color: clr(cb/5);
  padding: rem(10) rem(15);
  display: flex;
  align-items: flex-end;
  font-size: rem(22);
  line-height: rem(26);
  font-weight: 700;

  @media bp(lg) {
    padding: rem(15) 0 0 0;
    position: static;
    background-color: transparent;
    color: clr(b);
    height: auto;
  }
}

.image {
  border-radius: 0;

  @media bp(lg) {
    height: rem(320);
  }
}

.image,
.title {
  height: rem(178);

  @media bp(sm) {
    height: rem(320);
  }

  @media bp(md) {
    height: rem(440);
  }
}

.description {
  font-size: rem(14);
  line-height: rem(20);
  font-weight: 400;
  margin: rem(10) 0 rem(5);
  color: clr(g-7);

  @media bp(lg) {
    font-size: rem(20);
    line-height: rem(28);
    margin-top: rem(5);
  }
}

.author {
  color: clr(g-60);
}

.description,
.splitLine,
.author {
  margin-left: rem(15);
  margin-right: rem(15);

  @media bp(lg) {
    margin-left: 0;
    margin-right: 0;
  }
}
